.recentCats li {
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
}
.recentCats li a {
    display: inline-block;
    color: #fff;
    font-size:15px;
    position: relative;
    padding-left: 15px;
    text-transform: capitalize;
}
.recentCats li a:before {
    content:'';
    height: 5px;
    width:5px;
    background-color: #f00;
    position: absolute;
    left:0;
    top:50%;
    margin-top:-2.5px;
}