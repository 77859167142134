.ourProducts {
    padding: 100px 0;
}
.productStyle {
    position: relative;
}
.productStyle img {
    width:100%;
    display: inline-block;
}
.prodContent {
    position:absolute;
    left:50%;
    top:50%;
}
.prodContent h2 {
    font-size: 54px;
}
.prodContent h2,
.prodContent h3 {
    color: #EDEDED;
    text-transform: uppercase;
}
.prodContent p{
    color: #EDEDED;
    padding-top: 15px;
    padding-bottom: 30px;
}
@media only screen and (max-width: 640px) {
    .productStyle { padding-bottom: 40px; margin-top: 0 !important; }
    .prodContent h2 { font-size: 28px; }
    .prodContent { position: relative; transform: translate(0,0) !important; left:0; top:0 }
}