.field_option {
   width:100%;
   margin-top:30px;
   position: relative;
}
.field_option [type="email"],
.field_option [type="text"] {
    padding: 15px 0;
    width:100%;
    background:transparent;
    border:none;
    border-bottom: 1px solid #343434;
    color: #C2C2C2;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Open Sans', sans-serif;
    position: relative;
}
select {
    padding: 15px 0;
    color: #C2C2C2;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    display: inline-block;
    background: transparent;
    border:none;
    border-bottom: 1px solid #343434;
}
.field_option label {    
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #EDEDED;
    
}
.field_option textarea {
    padding: 15px 0;
    width:100%;
    background:transparent;
    border:none;
    border-bottom: 1px solid #343434;
    color: #C2C2C2;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 40px;
    font-family: 'Open Sans', sans-serif;
}
button[type='submit'] {
    cursor: pointer;
}
