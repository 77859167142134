@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;600;700&family=Open+Sans&display=swap');
* {
    box-sizing: border-box;
}
body {
    margin:0 auto;
    padding: 0;
    font-family: 'Chakra Petch', sans-serif;
    background: #1E1E1E;
}
/** Genral Code **/
a, a:focus, a:active {
    text-decoration: none;
    font-family: 'Chakra Petch', sans-serif;
}
li {
    list-style: none;
    font-family: 'Chakra Petch', sans-serif;
}
iframe {
    width: 100%;
    border:none;
    display: block;
}
p,
h1, h2, h3, h4, h5, h6,
ul, ol {
    margin:0;
    padding:0;
    font-family: 'Chakra Petch', sans-serif;
    letter-spacing: 0.3px;
}
input, input:focus, input:hover, textarea:focus, select {
    outline: none;
}

/** Common Classes **/
h1 {
    font-size: 66px;
    line-height: 70px;
}
h2 {
    font-size: 36px;
    line-height: 54px;
}
h3 {
    font-size: 28px;
    
}
h4 {
    font-size: 22px;
    line-height: 32px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}
p {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
}
.align-center {
    display:flex;
    align-items:center;
    
}
.gap {
    padding: 80px 0;
}
.r_top {
    padding-top:0;
}
.r_bottom {
    padding-bottom: 0;
}
.white-color {
    color: #FAFAFA;
}
.uppercase {
    text-transform: uppercase;
}
.btn-theme {
    padding: 10px 25px;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}
.border {
    border: 2px solid #f00;    
}
.btn-theme.border:before {
    content:'';
    background:#f00;
    height: 100%;
    width: 0;
    position: absolute;
    right: 0;
    top:0;
    z-index: -1;
    transition:all 0.2s ease-in;
}
.btn-theme.border:hover:before {
    width:100%;
}
.filled {
    background:#f00;
    border: 2px solid #f00;
}
.btn-theme.filled:hover {
    background:none;
}
.section-title {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
/** Header **/
.main_header {
    background: #161616;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width: 100%;
    transition: all 0.3s ease-in;
}
.main_header.fixed {
    position: fixed;
}
.site_logo {
    display: inline-block;
}
.site_logo a {
    display: inline-block;
}
.site_logo img {
    width: 60%;
    display: inline-block;
}
nav > ul > li {
    display: inline-block;
    position: relative;
    
}
nav > ul > li > a {
    color:#fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    letter-spacing: 0.5px;
    padding: 30px;
}
nav > ul > li > a:hover,
nav > ul > li > a.active {
    color:#f00;
    position:relative;
    transition:all 0.3s ease-in;
}
nav > ul > li > a:after {
    content:'';
    width:0;
    height:3px;
    background:#f00;
    position: absolute;
    top:100%;
    left:50%;
    margin-top: -3px;
    transition:all 0.2s ease-in;
}
nav > ul > li > a:hover:after,
nav > ul > li > a.active:after {
    width:100%;
    left:0;
}
nav > ul > li ul {
    position: absolute;
    left: 0;
    top:100%;
    width: 240px;
    background-color: #161616;
    display: none;
}
nav > ul > li ul li {
    width: 100%;
    display: inline-block;
}
nav > ul > li > ul > li > a {
    width: 100%;
    display: inline-block;
    padding: 16px 12px;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    border-bottom: 1px solid #252525;
    transition: all 0.3s ease-in;
}
nav > ul > li > ul > li > a:hover {
    background: #f00;
}
nav > ul > li:hover ul {
    display: inline-block;
}
.resp-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #222;
    padding: 10px 25px;
    position: relative;
    box-sizing: border-box;
}
.resp-logo img {
    max-width: 60%;
}
.resp-menu ul {
    position: absolute;
    width: 230px;
    background: #303030;
    right: 0;
    top: 100%;
    z-index: 99999999;
    display: none;
    transition:all 0.3s ease-in;
}
.resp-menu.show ul {
    display: inline-block;
}
.resp-menu ul li {
    width: 100%;
    display: inline-block;
}
.resp-menu ul li a {
    display: inline-block;
    width: 100%;
    padding: 15px 10px;
    color: #ccc;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 14px;
    border-bottom: 1px solid #3d3d3d;
    box-sizing: border-box;
}
.resp-menu ul li:last-child a {
    border-bottom:none;
}
.toggle-menu {
    width: 35px;
    height: 35px;
    background: #303030;
    display: inline-block;
    line-height: 37px;
    text-align: center;
    border-radius: 4px;
    font-size: 15px;
    color: #fff;
    position: relative;
    outline: none;
}
.toggle-menu:before {
    content: '';
    position: absolute;
    right: 5px;
    top: 100%;
    opacity:0;
    border-bottom: 15px solid #303030;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
}
.resp-menu.show .toggle-menu:before {
    opacity:1;
}
/* Page Banner */
.page_banner {
    padding: 40px 0;
    width: 100%;
    background:#121212;    ;
}
.page_banner h2 {
    color:#fff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    padding-top:10px;
}
ul.breadcrumbs {
    display: inline-flex;
    align-items: center;
}
.breadcrumbs li {
    padding:0 25px;
    position: relative;
}
.breadcrumbs li:first-child {
    padding-left:0;
}
.breadcrumbs li span,
.breadcrumbs li a {
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.3px;
}
.breadcrumbs li span {
    color:#f00;
}
.breadcrumbs li a:before {
    content: '';
    height: 5px;
    width: 5px;
    background: #f00;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -2.5px;
}
/** Page Default **/
.page_default {
    padding: 80px 0;
}
.page_default p {
    color:#EDEDED;
    padding: 10px 0;
    font-family: 'Open Sans', sans-serif;

}
.page_default h3 {
    color: #EDEDED;
    padding: 10px 0;
}
/** Footer **/
footer {
    display: inline-block;
    width:100%;
    background:#121212;
    padding: 100px 0;
}
.blog-sidebar {
    padding-left: 30px;
}
.widget-title {
    color:#EDEDED;
    padding-bottom: 20px;
}
.sideWidget {
    width:100%;
    display: inline-block;
    padding-top:20px;
    border-top: 2px solid #343434;
}
.sideWidget h4 {
    text-transform: uppercase;
    color: #EDEDED;
    padding-bottom: 10px;
}
button.scroll__top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: rgba(0,0,0,0.8);
    border: none;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}
.form__error {
    font-size: 12px;
    color: #f00;
    
    display: inline-block;
}
.input__error {
    border-bottom: 1px solid #f00 !important;
}
.success-msg {
    font-size: 14px;
    color: #fff;
    background: #15b40c;
    display: inline-flex;
    margin-left: 10px;
    padding: 10px 30px;
    align-items: baseline;
    justify-content: space-around;
}
.success-msg svg {
    margin-right: 5px;
}
.btn-theme.green{
    background:#15b40c !important;
    border-color: #15b40c;
}
@media only screen and (max-width: 1920px) {
}
@media only screen and (max-width: 1600px) {
}
@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1320px) {
}
@media only screen and (max-width: 1024px) {
	.main_header { display: none; }
	.resp-header { display:flex; }
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 640px) {
    h1 { font-size: 36px; line-height: 36px;}
    h2 {    font-size: 28px; line-height: 30px; }
    p { font-size: 15px; }
    .gap {padding: 30px 0; }
    .btn-theme { padding: 10px; font-size: 13px;}
    footer { padding: 40px 0;}
}
@media only screen and (max-width: 520px) {
}
@media only screen and (max-width: 460px) {
}
@media only screen and (max-width: 320px) {
}