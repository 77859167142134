.processes {
    padding-bottom: 80px;
}
.process {
    margin-top:40px;
    padding: 20px 0 20px;
    position: relative;
    border-top:1px solid #343434;
}
.process:before {
    content:'';
    height: 1px;
    width: 0;
    background:#f00;
    position: absolute;
    top:0;
    left:0;
    transition: all 0.3s ease-in;
}
.process:hover:before {
    width:100%;
}
.process h4 {
    padding-top:15px;
}
.process p {
    padding-top:15px;
    color: #EDEDED;
    font-family: 'Open Sans', sans-serif;
}
.processImg {
    display: flex;
    align-items: center;
    height: 70px;
}