.fancy_processes {
    padding: 100px 0;
}
.fancy_process {
    margin-top: 20px;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    position: relative;
    border-top:1px solid #7E7E7E;
    gap: 30px;
}
.fancy_process h4 {
    color: #EDEDED;
    text-transform: uppercase;
}
.fancy_process:before {
    content:'';
    height: 3px;
    width: 0;
    background:#f00;
    position: absolute;
    top:0;
    left:0;
    margin-top:-1.5px;
    transition: all 0.3s ease-in;
}
.fancy_process:hover:before {
    width:100%;
}
