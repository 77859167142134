.subscribeForm {
    display:inline-block;
    width:100%;
}
.subscribeForm label {
    width:100%;
    display:inline-block;
    color:#EDEDED;
}
.subscribeForm [type='email'] {
    box-sizing: border-box;
    width:100%;
    display:inline-block;
    font-size: 13px;
    color:#EDEDED;
    height: 45px;
    background:#171717;
    border:none;
    padding: 0 10px;
    margin: 5px 0 20px 0;
}
.subscribeForm button {
    width:100%;
}