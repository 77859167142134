.teamCard {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 40px;
}
.teamCard img {
    border-top:2px solid #343434;
    padding-top: 30px;
    width: 100%;
    display: inline-block;
}
.teamDetail {
    display: inline-block;
    padding-top: 10px;
    width:100%;
}
.teamDetail h4 {
    color:#EDEDED;
    text-transform: uppercase;
}
.teamDetail span {
    color:#EDEDED;
    text-transform: uppercase;
}
.teamCarousel{
    display: inline-block;
    width: 100%;
    position: relative;
}
.teamCarousel > div {
    margin: 0 -20px !important;
}
.slickArrows {
	position: absolute;
	right: 0;
	top: 0;
	background-color: transparent;
	border:1px solid #f00;
	height: 50px;
	width: 50px;
	color: #fff;
	font-size: 24px;
	line-height:55px;
	cursor: pointer;
	margin-top: 0px;
	transition: all 0.3s ease-in;
}
.slickArrows:hover {
	background:#f00;	
}
.prev {
	margin-right: 70px;
}