.footertMenu li a {
    font-size: 14px;
    color:#EDEDED;
    display:inline-block;
    position:relative;
    padding-left: 15px;
    margin-bottom: 10px;
}
.footertMenu li a:hover {
    color:#f00;
}
.footertMenu li a:after {
    content: '';
    height:4px;
    width:4px;
    background:#f00;
    position:absolute;
    left:0;
    top:50%;
    margin-top:-2px;
}
