.homeVideo,
.homeVideo img {
    display: inline-block;
    width: 100%;
    position: relative;
    box-sizing: border-box;
}
.homeVideo h2 {
    color: #EDEDED;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.homeVideo a {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}