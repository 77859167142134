.listmodernService {
    background:#161616;
    display: inline-block;
    width:100%;
}
.modernServices {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.serviceImg {
    display: block;
    width:50%;
    box-sizing: border-box;
}
.serviceImg img {
    width: 100%;
    display: block;
}
.serviceContent {
    box-sizing: border-box;
    width:50%;
    padding: 0px 40px;
}
.serviceContent h2 {
    color: #EDEDED;
    padding-bottom: 15px;
    line-height: 36px;
    text-transform: uppercase;
}
.serviceContent p {
    color: #EDEDED;
}
@media only screen and (max-width: 640px) {
    .modernServices { padding: 0 10px; }
    .modernServices:first-child { flex-direction: column; }
    .modernServices:last-child { flex-direction: column-reverse; }
    .serviceImg { width: 100%;}
    .serviceContent { margin: 20px 0; width: 100%; padding: 0 !important;}
}
