.about_widget p {
    color: #EDEDED;
    padding-top:10px;
    padding-bottom:20px;
}
.about_widget span {
    color:#fff;
    font-size: 12px;
    display: inline-block;
    padding-bottom:5px;
}
.about_widget strong {
    display: inline-block;
    width: 100%;
    color: #EDEDED;
}