.safety {
    padding: 80px 0;
}
.safetyProds {
    padding-top: 40px;
}
.safetyProds img {
    width:100%;
}
.prodImg {
    position: relative;
    display: inline-block;
    width:100%;
    overflow: hidden;
    transition: all 0.3s ease-in;
}
.prodImg img {
    transition: all 0.3s ease-in;
}
.prodImg:after,
.prodImg:before {
    content:'';
    top:0;
    position: absolute;
    width: 0;
    height: 100%;
    z-index: 10;
    background:rgba(0,0,0,0.2);
    transition: all 0.3s ease-in;
}
.prodImg:after {
    right: 0;
}
.prodImg:before {
    left:0;
}
.prodImg:hover img {
    transform: scale(1.1);
}
.prodImg:hover:after,
.prodImg:hover:before {
    width: 50%;
}