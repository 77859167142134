.sectHeading h2 {
    color:#EDEDED;
    text-transform: uppercase;
}
.sectHeading p {
    color:#EDEDED;
    padding-top: 15px;
}
.workingCard {
    border-top: 1px solid #343434;
    padding: 30px 0;
    position: relative;
}
.workingCard span {
    color:#f00;
    margin-right: 10px;
}
.workingCard h6 {
    color:#EDEDED;
    text-transform: uppercase;
    padding-bottom: 20px;
}
.workingCard p {
    color:#EDEDED;
}
.workingCard:before {
    content: '';
    height: 1px;
    width: 0;
    background: #f00;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in;
}
.workingCard:hover:before {
    width:100%;
}