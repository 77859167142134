.waterMark {
    margin: 0 auto;
    text-align: center;
    width:100%;
    display: inline-block;
    z-index: -1;
}
.waterMark img {
    width: 55%;
    margin: 0 auto;
}
.heroSect {
    padding-bottom: 70px;
    display: inline-block;
    width:100%;
    margin-top:-50px;
}
.heroContent {
    z-index:1;
    position: relative;
}
.heroContent h1 {
    text-transform: uppercase;
    color: #EDEDED;
    font-weight: 700;
    font-size: 70px;
    letter-spacing: 1px;
    line-height: 70px;
}
.heroContent p {
    font-size: 16px;
    line-height: 24px;
    color: #EDEDED;
    padding: 30px 0;
    font-family: 'Open Sans', sans-serif;
}
.heroImage img {
    width: 100%;
    display: inline-block;
}

@media only screen and (max-width: 640px) {
    .heroContent h1 {
        font-size: 36px;
        line-height: 36px;
    }
    .heroContent p {
        font-size: 15px;
    }
    .heroImage {
        margin-top:40px;
    }

}