.postCard {
    display:inline-block;
    width:100%;
    margin-bottom: 30px;
}
.postThumb img {
    width:100%;
    display: inline-block;
}
.postDetail a {
    display: inline-block;
    color:#EDEDED
    ;
    margin-top: 20px;
    margin-bottom: 10px;
}
.postDetail a:hover {
    color:#f00;
}
.postDetail p {
    color:#EDEDED;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
}
.postCats {
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap:20px;
}
.postCats li {
    color:#EDEDED;
    font-size: 13px;
    position: relative;
    padding-left: 10px;
}
.postCats li:before {
    content:'';
    height: 4px;
    width:4px;
    background-color: #f00;
    position: absolute;
    left:0;
    top:50%;
    margin-top:-2px;
}
.readMore {
    display: inline-block;
    text-transform: uppercase;
    color:#EDEDED;
    font-size: 14px;
}