.galleryItem,
.galleryItem a {
	display: inline-block;
	width: 100%;
	position: relative;
}
.galleryItem a {
	font-size: 32px;
	color:#fff;
	position: absolute;
	left:50%;
	top:50%;
	margin-top: -16px;
	margin-left:-16px;
	transition: all 0.3s ease-in;
	cursor: pointer;
	opacity:0
}
.galleryItem:before {
	content:'';
	left: 0;
	top:0;
	position: absolute;
	height: 100%;
	width: 100%;
	opacity:0;
	background:rgba(0,0,0,0.5);
}
.galleryItem:hover:before {
	opacity:1;
}
.galleryItem:hover a {
	opacity:1
}
.singleContent h2{
    color:#fff;
    padding-bottom:10px;
    border-bottom:2px solid #343434;
}
.singleContent p {
    color:#EDEDED;
    padding-top:20px;
    padding-bottom:20px;
}
.singleContent ul {
	flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    height: 100px;
}
.singleContent li {
    color:#EDEDED;
}
.singleContent img {
    width:100%;
    display:inline-block;
}
.galleries {
    padding-bottom: 70px;
    display:block;
    width:100%;
    position:relative;
}
.galleries h2 {
	padding-bottom: 40px;
}
.prodFeatures {
	padding-bottom: 60px;
	display: block;
	width: 100%;
}
.prodFeatures h2 {
	padding-bottom: 30px;
}
.featBox {
	border-top:2px solid #EDEDED;
	padding: 20px 40px 20px 30px;
	position: relative;
}
.featBox h5 {
	color:#EDEDED;
	text-transform: uppercase;
	padding-bottom: 10px;
	padding-top: 20px;
}
.featBox p {
	color:#EDEDED;
}
.prodCon {
	padding-bottom: 100px;
}
.prodCon h2 {
	padding-bottom: 30px;
}
.conVal {
	display: flex;
	align-items: end;
	gap: 20px;
	border-top:2px solid #EDEDED;
	padding: 30px 40px 50px 30px;
	position: relative;
}
.conVal img {
	width: 8%;
}
.conVal h6 {
	color:#EDEDED;
	text-transform: uppercase;
}
.slickArrows {
	position: absolute;
	right: 0;
	top: 0;
	background-color: transparent;
	border:1px solid #f00;
	height: 50px;
	width: 50px;
	color: #fff;
	font-size: 24px;
	line-height:55px;
	cursor: pointer;
	margin-top: 0px;
	transition: all 0.3s ease-in;
}
.slickArrows:hover {
	background:#f00;	
}
.prev {
	margin-right: 70px;
}
.conVal:before,
.featBox:before {
    content: '';
    height: 2px;
    width: 0;
    background: #f00;
    position: absolute;
    top: -2px;
    left: 0;
    transition: all 0.3s ease-in;
}
.conVal:hover:before,
.featBox:hover:before {
    width:100%;
}
@media only screen and (max-width: 640px) {
    .galleryItem img {width:100%;}
	.singleContent img { margin-top: 40px; }
}