.contactBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    padding: 20px 40px 60px; 
    border:1px solid #343434;
    height: 180px;
}
.contactBox h4 {
    padding-bottom: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: #EDEDED;
}
.contactBox p {
    color: #EDEDED;
}
.contactBox ul {
    column-count: 2;
}
.contactBox ul li {
    color: #EDEDED;
}
.contactMap {
    display: inline-block;
    width:100%;
    margin-bottom: 100px;
    position: relative;
}
.contactMap img {
    width:100%;
}
.pointer {
    border-radius: 50%;
    height: 20px;
    padding: 2px;
    position: absolute;
    width: 20px;
    background:transparent;
    top: 47%;
    left: 60.7%;
    box-sizing: border-box;
}
.pointer:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.addressTooltip {
    left: -100px;
    position: absolute;
    top: 100%;
    margin-top:20px;
    transform: scale(0);
    transition: all 0.5s ease 0s;
    width: 300px;
    padding: 20px;
    z-index: 1;
    background-color: #fff;
}
.addressTooltip::before {
    border-bottom: 10px solid #fff;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    content: "";
    left: 96px;
    position: absolute;
    top: -10px;
}
.pointer:hover .addressTooltip {
    transform: scale(1);
}
@media only screen and (max-width: 640px) {
    .contactBox { margin-bottom: 10px; height: auto; padding: 20px; }
}