.aboutMic {
    background: #121212;
    padding: 80px;
}
.aboutMic h2 {
    text-transform: uppercase;
    color: #EDEDED;
    padding-bottom: 10px;
}
.aboutMic p {
    color: #EDEDED;
    padding-top: 15px;
    border-top: 2px solid #343434;
    font-family: 'Open Sans', sans-serif;

}
.aboutMic ul {
    display: inline-flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    padding-top: 70px;
    gap: 20px;
}

@media only screen and (max-width: 640px) {
    .aboutMic { padding: 30px 20px; }
    .aboutMic ul { justify-content: left; padding-top: 40px;}
}