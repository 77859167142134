.topImg {
    width:100%;
    display:inline-block;
    padding: 50px 0;
}
.topImg img {
    width:100%;
    display:inline-block;
}
.prodTitle {
    padding-bottom:20px;
}
.prodTitle h3{
    color:#fff;
    text-transform:uppercase;
}
.prodItem {
    box-sizing:border-box;
    width:100%;
    display:inline-block;
    border-top: 2px solid #7E7E7E;
    padding: 40px 50px;
}
.prodItem:hover {
    border-color:#f00;
    cursor: pointer;
}
.prodItem img {
    width:100%;
}
.prodItem a {
    color:#fff;
    font-weight: 700;
    font-size: 18px;
    margin: 30px 0;
    display:block;
}